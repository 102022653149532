import type { ReactNode, HTMLAttributes } from 'react';
import { useState, useCallback } from 'react';
import styled from 'styled-components';

import { Collapse } from '../collapse';

export interface AccordionProps extends HTMLAttributes<HTMLDivElement> {
  content: ReactNode;
  summary: (state: { isOpen: boolean }) => ReactNode;
  className?: string;
  title: string;
  onToggle?: (title: string) => void;
}

const SummaryContainer = styled.div`
  cursor: pointer;
`;

export const Accordion = ({ content, summary, className, title, onToggle, ...rest }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSummaryClick = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    onToggle?.(title);
  }, [onToggle, title]);

  return (
    <>
      <SummaryContainer className={className} onClick={handleSummaryClick} {...rest}>
        {summary({ isOpen })}
      </SummaryContainer>
      <Collapse isOpen={isOpen}>{content}</Collapse>
    </>
  );
};
