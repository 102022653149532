import styled, { css } from 'styled-components';

import type { TextSectionComponentFields } from '@seuk/cms-api-client';
import type { NextImageProps } from '@seuk/design-system/atoms';
import { Row, Column, NextImage, ROW_BREAKPOINT, RichTextWrapper } from '@seuk/design-system/atoms';
import { ButtonLinkNext } from '@seuk/design-system/molecules';

import type { TextSectionProps } from './text-section';

export const TextSectionContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const TextSectionContainerRow = styled(Row)<{ bgcolor?: string }>`
  ${({ theme, bgcolor }) => css`
    width: 100%;
    align-items: stretch;
    background-color: ${bgcolor ?? 'white'};
    border-radius: ${theme.radiusLg};
    ${theme.breakpoints.up(ROW_BREAKPOINT)} {
      flex: 1;
    }
  `}
`;

export const TextSectionContainerColumn = styled(Column)<{ bgcolor?: string }>`
  ${({ theme, bgcolor }) => css`
    width: 100%;
    align-items: stretch;
    background-color: ${bgcolor ?? 'white'};
    border-radius: ${theme.radiusLg};

    ${theme.breakpoints.up(ROW_BREAKPOINT)} {
      flex: 1;
    }
  `}
`;

export const TextColumn = styled(Column)`
  ${({ theme }) => css`
    padding: ${theme.spacing(8)};
    box-sizing: border-box;
    flex 50%;

    ${theme.breakpoints.up(ROW_BREAKPOINT)} {
      margin-bottom: 0;
    }
  `}
`;

export const ImageColumn = styled(Column)<{ hasIcon: boolean; iconPosition?: TextSectionProps['iconPosition'] }>`
  ${({ theme, hasIcon }) => css`
    flex 50%;

    // On mobile icons are always located at the bottom
    ${theme.breakpoints.down(ROW_BREAKPOINT)} {
      ${hasIcon ? `margin-bottom: ${theme.spacing(14)};` : ''}
    }
  `}
`;

export const SectionTitle = styled.h3<{ align?: string }>`
  ${({ theme, align }) => css`
    ${theme.typography.heading[2].demi};
    margin-top: 0;
    margin-bottom: ${theme.spacing(6)};
    text-align: ${align};
    color: ${theme.palette.focus.strong};

    ${theme.breakpoints.up(ROW_BREAKPOINT)} {
      margin-bottom: ${theme.spacing(8)};
    }
  `}
`;

export const SubtitleImageWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  ${({ theme }) => theme.breakpoints.up(ROW_BREAKPOINT)} {
    margin-bottom: ${({ theme }) => theme.spacing(6)};
  }
`;

const getImageSrc = (image: NextImageProps) => (typeof image.src === 'string' ? image.src : '');

export const ButtonLinkWithBackground = styled(ButtonLinkNext)<{ image?: NextImageProps; bgColor?: string }>`
  ${({ image, bgColor }) =>
    css`
      ${image
        ? // Increased specificity to overwrite base styles
          css`
            && {
              background: url(${getImageSrc(image)});
              width: ${image.width}px;
              height: ${image.height}px;
              padding: 0;
              border: none;
            }
          `
        : css`
            ${({ theme }) => theme.breakpoints.down(ROW_BREAKPOINT)} {
              width: 100%;
              text-align: center;
            }
          `}

      ${bgColor
        ? css`
            background-color: ${bgColor};
          `
        : ''}
    `}
`;

function defineImageRoundedCorners(radius: string, imagePosition?: string) {
  switch (imagePosition) {
    case 'Top':
      return css`
        border-radius: ${radius} ${radius} 0 0;
      `;

    case 'Right':
      return css`
        border-radius: 0 ${radius} ${radius} 0;
      `;

    default: // Default position is Left
      return css`
        border-radius: ${radius} 0 0 ${radius};
      `;
  }
}

export const StyledImage = styled(NextImage)<{ $imagePosition?: string; $imageFit?: boolean }>`
  ${({ theme, $imagePosition, $imageFit }) => css`
    ${defineImageRoundedCorners(theme.radiusLg, $imagePosition)};

    width: 100%;
    height: 100%;
    object-fit: ${$imageFit ? 'contain' : 'cover'};

    ${theme.breakpoints.down('sm')} {
      border-radius: ${theme.radiusLg} ${theme.radiusLg} 0 0;
    }
  `}
`;

export const SectionSubTitle = styled.h4<{ align?: string }>`
  ${({ theme, align }) => css`
    ${theme.typography.paragraph.medium.normal};
    ${theme.typography.label.large};
    color: ${theme.palette.text.onSurface.subtle};
    margin-top: 0;
    margin-bottom: ${theme.spacing(6)};
    ${align &&
    css`
      text-align: ${align};
    `}
  `}
`;

type DescriptionProps = {
  align?: string;
  hasBottomMargin: boolean;
  fontSize?: TextSectionComponentFields['descriptionFontSize'];
};

export const DESCRIPTION_FONT_SIZE = {
  small: 'Small',
  normal: 'Normal',
  large: 'Large',
};

function getDescriptionFontSize(fontSize: TextSectionComponentFields['descriptionFontSize']) {
  switch (fontSize) {
    case DESCRIPTION_FONT_SIZE.large:
      return css`
        ${({ theme }) => theme.typography.heading[5].normal}
      `;

    case DESCRIPTION_FONT_SIZE.small:
      return css`
        ${({ theme }) => theme.typography.paragraph.small.normal}
      `;

    default:
      return css`
        ${({ theme }) => theme.typography.paragraph.medium.normal}
      `;
  }
}

export const Description = styled(RichTextWrapper)<DescriptionProps>`
  ${({ theme, hasBottomMargin, align, fontSize }) => {
    const fontSizeStyles = getDescriptionFontSize(fontSize);
    return css`
      ${fontSizeStyles}

      a {
        ${fontSizeStyles}
      }

      ${hasBottomMargin &&
      css`
        margin-bottom: ${theme.spacing(8)};
      `}

      ${align &&
      css`
        text-align: ${align};
      `}
    `;
  }}
`;

export const StyledCheckList = styled.ul`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${theme.spacing(4)};
    margin: ${theme.spacing(6)} 0;
    list-style-type: none;
    padding: 0;

    h3 {
      ${theme.typography.heading[4].normal};
      color: ${theme.palette.focus.strong};
      font-weight: 400;
      margin: 0;
    }

    ${theme.breakpoints.down('lg')} {
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: ${theme.spacing(4)};
    }
  `};
`;

export const StyledCheckListItem = styled.li`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    margin: ${theme.spacing(4)} 0;
    &::before {
      content: '';
      display: inline-block;
      min-width: 30px;
      min-height: 30px;
      margin-right: ${theme.spacing(6)};
      background-image: url('/icons/checkmark-yellow.svg'); /* replace with your image path */
      background-size: contain;
      background-repeat: no-repeat;
    }
  `};
`;
