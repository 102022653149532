import React, { memo, useMemo } from 'react';
import type { ReactNode } from 'react';
import { useTheme } from 'styled-components';

import type { RichText, TextSectionComponentFields } from '@seuk/cms-api-client';
import { richTextToReactNode } from '@seuk/cms-api-client';

import { Flexbox, NextImage, ROW_BREAKPOINT } from '../../atoms';
import type { NextImageProps, JustifyContent } from '../../atoms';
import { ButtonLink } from '../../molecules';

import { MarketingBadge } from './marketing-badge';
import type { MarketingBadgeProps } from './marketing-badge';
import {
  ButtonLinkWithBackground,
  Description,
  ImageColumn,
  SectionSubTitle,
  SectionTitle,
  StyledCheckList,
  StyledImage,
  SubtitleImageWrapper,
  TextColumn,
  StyledCheckListItem,
  TextSectionContainer,
  TextSectionContainerRow,
  TextSectionContainerColumn,
} from './text-section.styles';

type ImagePosition = 'Left' | 'Right' | 'Top';

export interface TextSectionButtonProps {
  readonly title: string;
  readonly href: string;
  readonly isExternal?: boolean;
  readonly clickEventName?: string;
  readonly image?: NextImageProps | null;
  readonly style?: 'Primary' | 'Secondary' | null;
  readonly backgroundColor?: string;
}

export type SerializedTextSectionProps = Omit<TextSectionProps, 'description'> & { description?: RichText };

export interface TextSectionProps {
  readonly className?: string;
  readonly title?: string;
  readonly subtitle?: string;
  readonly subtitleImage?: NextImageProps | null;
  readonly description?: ReactNode;
  readonly image?: NextImageProps | null;
  readonly imageAlt?: string | null;
  readonly imagePosition: ImagePosition;
  readonly imageFit?: boolean;
  readonly icon?: MarketingBadgeProps['icon'];
  readonly iconPosition: MarketingBadgeProps['location'];
  readonly iconBackgroundColor?: MarketingBadgeProps['backgroundColor'];
  readonly buttons?: readonly TextSectionButtonProps[];
  readonly checkListItems?: SerializedTextSectionProps[];
  readonly backgroundColor?: string;
  readonly alignItems?: 'left' | 'center' | 'right';
  readonly descriptionFontSize?: TextSectionComponentFields['descriptionFontSize'];
}

const TextSectionComponent = ({
  icon,
  iconPosition,
  iconBackgroundColor,
  image,
  imageAlt,
  imagePosition,
  imageFit,
  buttons = [],
  description,
  subtitle,
  subtitleImage,
  title,
  className,
  checkListItems,
  backgroundColor,
  alignItems,
  descriptionFontSize,
}: TextSectionProps) => {
  const theme = useTheme();

  const ContentContainer = imagePosition !== 'Top' ? TextSectionContainerRow : TextSectionContainerColumn;

  const buttonLink = (button: TextSectionButtonProps) => {
    return (
      <ButtonLinkWithBackground
        color={button.style ? button.style.toLowerCase() : 'primary'}
        href={button.href}
        key={button.href}
        image={button.image}
        {...(button.isExternal
          ? {
              as: ButtonLink,
              target: '_blank',
              rel: 'noopener noreferrer',
              $newPage: true,
            }
          : {})}
      >
        {button.title}
      </ButtonLinkWithBackground>
    );
  };

  const titleAlignment = useMemo(() => {
    if (alignItems) {
      return alignItems;
    }
    if (!image) {
      return 'center';
    }
    return 'left';
  }, [alignItems, image]);

  return (
    <TextSectionContainer>
      <ContentContainer
        alignTop
        alignCenter
        reverse={imagePosition === 'Right'}
        bgcolor={(image && 'white') || backgroundColor || 'transparent'}
        className={className}
      >
        {image && (
          <ImageColumn hasIcon={Boolean(icon)} iconPosition={iconPosition}>
            {icon && <MarketingBadge icon={icon} location={iconPosition} backgroundColor={iconBackgroundColor} />}
            <StyledImage
              quality="100"
              $imageFit={Boolean(imageFit)}
              $imagePosition={imagePosition ?? 'Left'}
              sizes={`(max-width: ${theme.breakpoints.values[ROW_BREAKPOINT]}px) 90vw, (min-width: ${theme.breakpoints.values[ROW_BREAKPOINT]}px) 40vw`}
              {...image}
              alt={imageAlt ?? ''}
            />
          </ImageColumn>
        )}

        <TextColumn>
          {subtitle && <SectionSubTitle align={alignItems}>{subtitle}</SectionSubTitle>}

          {subtitleImage && (
            <SubtitleImageWrapper>
              <NextImage aria-hidden="true" {...subtitleImage} alt={subtitleImage.alt} />
            </SubtitleImageWrapper>
          )}

          {title && <SectionTitle align={titleAlignment}>{title}</SectionTitle>}

          <Description hasBottomMargin={buttons.length > 0} align={alignItems} fontSize={descriptionFontSize}>
            {description}
          </Description>

          <Flexbox justifyContent={alignItems as JustifyContent} gap={theme.spacing(8)}>
            {buttons.map(buttonLink)}
          </Flexbox>

          {!!checkListItems?.length && (
            <StyledCheckList>
              {checkListItems.map((item) => (
                <StyledCheckListItem key={item.title}>
                  <div>
                    <h3>{item.title}</h3>
                    {item.description && <div>{richTextToReactNode(item.description)}</div>}
                  </div>
                </StyledCheckListItem>
              ))}
            </StyledCheckList>
          )}
        </TextColumn>
      </ContentContainer>
    </TextSectionContainer>
  );
};

export const TextSection = memo(TextSectionComponent);
