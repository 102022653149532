import { format } from 'date-fns';
import NextLink from 'next/link';
import { memo } from 'react';
import type { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import type { ArticlePageFields } from '@seuk/cms-api-client';

import { Flexbox, NextImage, RichTextWrapper } from '../../atoms';
import type { NextImageProps } from '../../atoms';
import { ButtonLinkNext } from '../../molecules';

export interface CardButtonProps {
  readonly title: string;
  readonly href: string;
  readonly isExternal?: boolean;
  readonly clickEventName?: string;
  readonly image?: NextImageProps | null;
  readonly style?: 'Primary' | 'Secondary' | null;
}

export interface CardInterface {
  icon?: string;
  iconAlt?: string | null;
  animation?: { json: string; trigger: string; state: string };
  image?: NextImageProps | null;
  imageAltText?: string | null;
  useCircleIcon?: boolean;
  title: string;
  description: ReactNode;
  categories?: ArticlePageFields['categories'];
  date?: string;
  readingTime?: number;
  linkText?: string;
  linkURL?: string;
  isExternalLink?: boolean;
  button?: CardButtonProps | null;
}

export interface MarketingCardsProps {
  title?: string;
  cards: CardInterface[];
  showTitle: boolean | null;
  columnsAmount: number | null;
  cardsBackgroundColor?: string;
  titleAsLink?: boolean;
  onLinkClick?: (link: { text: string; href: string }) => void;
}

export const MarketingCardsGrid = styled.div<{ mb?: number; columns: number | null }>`
  ${({ theme, mb, columns }) => css`
    --grid-columns: ${columns ?? 3};
    display: grid;
    grid-row-gap: 2rem;
    grid-column-gap: 2rem;
    grid-auto-flow: row;
    margin-bottom: ${mb ? theme.spacing(mb) : ''};
    grid-template-columns: repeat(var(--grid-columns), 1fr);

    ${theme.breakpoints.down('lg')} {
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 1rem;
    }
  `}
`;

const SectionTitle = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.heading[2].demi};
    margin-top: 0;
    margin-bottom: ${theme.spacing(12)};
    color: ${theme.palette.focus.strong};
    padding: 0 ${theme.spacing(48)};
    text-align: center;
  `}
`;

const Card = styled.div<{ withImages?: boolean; shadowActive?: boolean; itemsCenter?: boolean; bgColor?: string }>`
  ${({ theme, itemsCenter, bgColor }) => css`
    border-radius: ${theme.radius};
    display: flex;
    flex-direction: column;
    background-color: ${bgColor ?? 'transparent'};
    align-items: ${itemsCenter ? 'center' : 'start'};
    padding: ${bgColor ? theme.spacing(6) : '0'};
  `}
`;

const CardHeader = styled.div<{ useBulletIcon?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ useBulletIcon }) => (useBulletIcon ? 'column' : 'row')};
`;

const CardIconWrapper = styled.div<{ useBulletIcon?: boolean }>`
  position: relative;

  ${({ theme, useBulletIcon }) => css`
    margin-bottom: ${theme.spacing(3)};
    margin-right: ${theme.spacing(6)};

    ${useBulletIcon
      ? css`
          background-color: ${theme.palette.focus.strong};
          min-width: ${theme.spacing(20)};
          height: ${theme.spacing(20)};
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          padding: 0;

          div {
            position: relative;
            width: 100%;
            height: 100%;
            margin: 20px;
          }
        `
      : css`
          width: 3.125rem;
          height: 3.125rem;

          img {
            object-fit: contain;
          }
        `}
  `}
`;

const CardTitle = styled.a<{ alignCenter?: boolean; isLink?: boolean }>`
  ${({ theme, alignCenter, isLink }) => css`
    padding: 0;
    ${theme.typography.heading[3].demi}
    color: ${isLink ? theme.palette.accent.tertiary.strong : theme.palette.focus.strong};
    text-align: ${alignCenter ? 'center' : 'left'};
    text-decoration: none;

    &:hover {
      text-decoration: ${isLink ? 'underline' : 'none'};
    }
  `}
`;

const Link = styled(NextLink)`
  ${({ theme }) => css`
    justify-self: flex-end;
    margin-top: auto;
    margin-bottom: 0;
    text-decoration: 'underline';
    color: ${theme.palette.focus.strong};
    ${theme.typography.label.extraLarge};
  `}
`;

const CardImg = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(6)};
  width: 100%;
  padding-bottom: 80%;
  position: relative;
  height: 0;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px 16px 0 0;
  }
`;

const StyledImage = styled(NextImage)`
  width: 100%;
  height: auto;
`;

const Description = styled(RichTextWrapper)<{ alignCenter?: boolean }>`
  ${({ theme, alignCenter }) => css`
    margin: ${theme.spacing(6)} 0;
    padding: 0;
    text-align: ${alignCenter ? 'center' : 'left'};
  `}
`;

const ButtonLink = styled(ButtonLinkNext)`
  align-self: flex-start;
  margin: 0;
`;

const CategoryBadge = styled.div`
  ${({ theme }) => css`
    ${theme.typography.label.medium.bold};
    background-color: ${theme.palette.accent.primary.strong};
    padding: ${theme.spacing(3)} ${theme.spacing(4)};
    border-radius: ${theme.radius};
  `}
`;

export const Categories = styled(Flexbox)`
  ${({ theme }) => css`
    position: absolute;
    left: ${theme.spacing(6)};
    bottom: ${theme.spacing(6)};
    flex-wrap: wrap;
    gap: ${theme.spacing(3)};
  `}
`;

const TimingWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(4)};
    align-self: stretch;
  `}
`;

const ArticleDate = styled.span`
  ${({ theme }) => css`
    ${theme.typography.label.large}
  `}
`;

const MarketingCardsComponent = ({
  cards,
  title,
  showTitle,
  titleAsLink,
  columnsAmount,
  cardsBackgroundColor,
}: MarketingCardsProps) => {
  return (
    <section>
      {showTitle && title && <SectionTitle>{title}</SectionTitle>}

      <br />

      <MarketingCardsGrid data-testid="marketing-cards" columns={columnsAmount}>
        {cards.map((card) => (
          <Card
            key={card.title}
            withImages={Boolean(card.image)}
            itemsCenter={card.useCircleIcon}
            bgColor={cardsBackgroundColor}
          >
            {card.image && (
              <CardImg>
                <StyledImage {...card.image} {...(card.imageAltText ? { alt: card.imageAltText } : {})} quality="100" />
                {card.categories && (
                  <Categories>
                    {card.categories.map((category) => (
                      <CategoryBadge key={category}>{category}</CategoryBadge>
                    ))}
                  </Categories>
                )}
              </CardImg>
            )}

            {card.date && (
              <TimingWrapper>
                <ArticleDate>{format(new Date(card.date), 'dd MMM yyyy')}</ArticleDate>
                <span>
                  {card.readingTime} minute{Number(card.readingTime) > 1 && 's'}
                </span>
              </TimingWrapper>
            )}

            <CardHeader useBulletIcon={card.useCircleIcon}>
              {card.icon && (
                <CardIconWrapper useBulletIcon={card.useCircleIcon} aria-hidden="true">
                  <div>
                    <NextImage unoptimized={true} src={card.icon ?? ''} alt={card.iconAlt ?? ''} fill />
                  </div>
                </CardIconWrapper>
              )}

              <CardTitle
                alignCenter={card.useCircleIcon}
                isLink={titleAsLink}
                href={titleAsLink ? card?.button?.href : undefined}
                rel="noopener noreferrer"
              >
                {card.title}
              </CardTitle>
            </CardHeader>

            <Description alignCenter={card.useCircleIcon}>{card.description}</Description>
            {card.linkURL && (
              <Link
                href={card.linkURL}
                {...(card.isExternalLink
                  ? {
                      target: '_blank',
                      rel: 'noopener noreferrer',
                      $newPage: true,
                    }
                  : { target: '_self' })}
              >
                {card.linkText}
              </Link>
            )}

            {card.button && (
              <ButtonLink
                href={card.button.href}
                key={card.button.href}
                color={(card.button.style?.toLowerCase() ?? 'primary') as 'primary' | 'secondary' | undefined}
                {...(card.button.isExternal
                  ? {
                      target: '_blank',
                      rel: 'noopener noreferrer',
                      $newPage: true,
                    }
                  : { target: '_self' })}
              >
                {card.button?.title}
              </ButtonLink>
            )}
          </Card>
        ))}
      </MarketingCardsGrid>
    </section>
  );
};

export const MarketingCards = memo(MarketingCardsComponent);
