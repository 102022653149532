import Head from 'next/head';
import Link from 'next/link';
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Icon } from '@seuk/design-system/atoms';

export const BreadcrumbsContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing(8)};
  padding-bottom: ${({ theme }) => theme.spacing(8)};
  padding-left: 0;
  padding-right: 0;

  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
`;

export interface BreadcrumbsProps {
  items: { label: string; href?: string }[];
  backHref: string;
}

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Chevron = styled(Icon)`
  transform: rotateZ(-90deg);
  margin-bottom: -4px;
  margin-left: ${({ theme }) => theme.spacing(2)};
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

const ChevronBack = styled(Icon)`
  transform: rotateZ(90deg);
  margin-bottom: -4px;
  margin-left: ${({ theme }) => theme.spacing(2)};
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

const LinkText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.text.onSurface.strong};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const DesktopLinks = styled.div`
  display: none;
  max-width: 100%;
  align-items: center;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: flex;
  }
`;

const MobileBackButton = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: none;
  }
`;

const separator = <Chevron name="Chevron" width={12} height={12} color="#404040" />;

const BreadcrumbLink = (props: { text: string; href?: string; isLink?: boolean }) => {
  return props.isLink ? (
    <StyledLink href={props.href || ''} title={props.text}>
      {props.text}
    </StyledLink>
  ) : (
    <LinkText title={props.text}>{props.text}</LinkText>
  );
};

const scriptTemplate = ({ items }: BreadcrumbsProps) => {
  const result = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: items.map(({ label, href }, index) => {
      const res: Record<string, unknown> = {
        '@type': 'ListItem',
        position: index + 1,
        name: label,
      };
      if (href) {
        res['item'] = process.env['NEXT_PUBLIC_WEBSITE_URL'] + href;
      }
      return res;
    }),
  };

  return JSON.stringify(result, null, 2);
};

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  return (
    <>
      <Head>
        <script type="application/ld+json">{scriptTemplate(props)}</script>
      </Head>
      <BreadcrumbsContainer>
        <LinksContainer>
          <MobileBackButton>
            <ChevronBack name="Chevron" width={12} height={12} color="#404040" />
            <BreadcrumbLink text="Return" href={props.backHref} isLink />
          </MobileBackButton>
          <DesktopLinks>
            {props.items.map(({ href, label }, index) => {
              const link = <BreadcrumbLink href={href} text={label} isLink={index !== props.items.length - 1} />;
              if (index !== 0) {
                return (
                  <Fragment key={`${href}${label}`}>
                    {separator}
                    {link}
                  </Fragment>
                );
              }
              return <Fragment key={`${href}${label}`}>{link}</Fragment>;
            })}
          </DesktopLinks>
        </LinksContainer>
      </BreadcrumbsContainer>
    </>
  );
};
