import styled, { css } from 'styled-components';

import type { GroupFields } from '@seuk/cms-api-client';

import { Flexbox } from '../flexbox';

const DEFAULT_ITEMS_SPACING_UNIT = 6;

export const GROUP_SPACING_VALUES_FACTOR = {
  small: 1,
  medium: 2,
  large: 3,
};

export const ZebraSection = styled.section<{ bgColor?: string; spacing?: number }>`
  ${({ theme, bgColor, spacing }) => css`
    padding: ${spacing !== undefined
        ? theme.spacing(spacing * DEFAULT_ITEMS_SPACING_UNIT)
        : theme.spacing(GROUP_SPACING_VALUES_FACTOR.medium * DEFAULT_ITEMS_SPACING_UNIT)}
      0;

    &:nth-of-type(even) {
      background-color: ${bgColor ?? theme.palette.background.surface};
    }

    &:nth-of-type(odd) {
      background: ${bgColor ?? theme.palette.background.base};
    }
  `}
`;

/**
 * Visually invisible component that's mostly used to shift zebra pattern
 */
export const EmptyZebraSection = styled(ZebraSection)`
  && {
    margin: 0;
    padding: 0;
  }
`;

export const SectionGroup = styled(Flexbox).attrs(() => ({
  flexDirection: 'column',
}))<{ spacing?: GroupFields['itemsSpacing'] }>`
  gap: ${({ theme, spacing }) =>
    theme.spacing(
      DEFAULT_ITEMS_SPACING_UNIT *
        (GROUP_SPACING_VALUES_FACTOR[spacing?.toLowerCase() as keyof typeof GROUP_SPACING_VALUES_FACTOR] ??
          GROUP_SPACING_VALUES_FACTOR.medium)
    )};
`;

/**
 * Made to wrap multiple zebra sections to arrange automatic pattern
 */
export const Main = styled.main`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.palette.background.surface};

    > ${ZebraSection}:nth-of-type(odd) {
      &:last-child {
        border-bottom: ${theme.spacing(12)} solid ${theme.palette.background.surface};
      }
    }
  `}
`;

export const ROW_BREAKPOINT = 'md';

export const Row = styled.div<{ alignTop?: boolean; alignCenter?: boolean; reverse?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignTop }) => (alignTop ? 'flex-start' : 'center')};
  ${({ theme }) => theme.breakpoints.up(ROW_BREAKPOINT)} {
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
    justify-content: space-evenly;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    ${({ reverse }) => {
      return reverse ? '.badge-position--bottom {left: auto;right: 3.875rem}' : '';
    }}
  }
`;

export const Column = styled.div<{ alignTop?: boolean; alignCenter?: boolean; reverse?: boolean }>`
  position: relative;
  align-items: ${({ alignCenter }) => (alignCenter ? 'flex-start' : 'center')};
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex: 1;
  }
`;

export const TextWithMargin = styled.p<{ mb?: number }>`
  ${({ theme }) => theme.typography.paragraph.medium.normal};
  margin-bottom: ${({ theme, mb = 0 }) => theme.spacing(mb * 0.75)};
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: ${({ theme, mb = 0 }) => theme.spacing(mb)};
  }
`;

export const RichTextWrapper = styled.div`
  white-space: pre-line;

  > p:only-child {
    margin: 0;
  }

  a {
    ${({ theme }) => theme.typography.paragraph.medium.normal};
    color: inherit;
  }

  ul,
  ol {
    padding-left: ${({ theme }) => theme.spacing(5)};
  }

  li p {
    margin: 0;
  }
`;
