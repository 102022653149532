export enum EMarketingSectionType {
  TableSection,
  TextSection,
  VideoSection,
  GroupSection,
  USPListSection,
  InformationalCardListSection,
  ProductsCardListSection,
  StepsSection,
  FAQListSection,
}
