import { memo } from 'react';
import styled from 'styled-components';

import type { RichText } from '@seuk/cms-api-client';
import { richTextToReactNode } from '@seuk/cms-api-client';

import { RichTextWrapper, Flexbox, Icon, Typography } from '../../atoms';
import { ButtonLink, Accordion } from '../../molecules';

interface Link {
  label: string;
  href: string;
}

export interface LinksSection {
  title: string;
  description?: RichText;
  links: Link[];
}

const FAQContainer = styled.div`
  margin: 0 auto;
  width: 100%;
`;

const FAQQuestionListContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(8)} 0;
`;

const AccordionContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.text.onSurface.inactive};
  padding: ${({ theme }) => theme.spacing(4)} 0;
`;

const FAQQuestion = styled(Typography).attrs({ variant: 'lead', weight: 'normal' })<{ isActive: boolean }>`
  ${({ isActive }) => (isActive ? 'text-shadow: 1px 0 0 currentColor; letter-spacing: 0.5px;' : '')}
  transition-property: text-shadow, letter-spacing;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
`;

const FAQAnswerContainer = styled(RichTextWrapper)`
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  ${({ theme }) => theme.typography.paragraph.medium.normal};

  a {
    color: ${({ theme }) => theme.palette.focus.strong};
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
    font-weight: bold;
    white-space: nowrap;
  }
`;

const AccordionIcon = styled(Icon)`
  flex-shrink: 0;
`;

export type FAQListProps = {
  className?: string;
  items: readonly LinksSection[];
  title?: string;
  buttonLink?: Link | null;
};

export const FAQList = memo(({ className, items, title, buttonLink }: FAQListProps) => {
  return (
    <FAQContainer className={className}>
      {title && (
        <Typography variant="h2" weight="bold" notch={true} component="h2">
          {title}
        </Typography>
      )}
      <FAQQuestionListContainer>
        {items.map((section) => (
          <AccordionContainer key={section.title}>
            <Accordion
              title={section.title}
              summary={({ isOpen }) => (
                <Flexbox justifyContent="space-between" data-question>
                  <FAQQuestion isActive={isOpen}>{section.title}</FAQQuestion>
                  <AccordionIcon name={isOpen ? 'Minus' : 'Plus'} />
                </Flexbox>
              )}
              content={
                <FAQAnswerContainer>
                  {section.description &&
                    richTextToReactNode(section.description, { shouldOpenLinkInNewTab: () => true })}
                  {section.links.map((link) => (
                    <a href={link.href} key={link.href} target="_blank" rel="noreferrer">
                      {link.label}
                    </a>
                  ))}
                </FAQAnswerContainer>
              }
            />
          </AccordionContainer>
        ))}
      </FAQQuestionListContainer>
      {buttonLink && (
        <ButtonLink href={buttonLink.href} color="secondary">
          {buttonLink.label}
        </ButtonLink>
      )}
    </FAQContainer>
  );
});

FAQList.displayName = 'FAQContainer';
