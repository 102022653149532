import { memo } from 'react';
import type { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { RichTextWrapper, Typography } from '../../atoms';
import { ButtonLinkNext } from '../../molecules';

import type { CardButtonProps } from './marketing-cards';
import { MarketingCardsGrid } from './marketing-cards';

export interface ProductCardInterface {
  title: string;
  subtitle?: string;
  description: ReactNode;
  bulletItems?: string[] | null;
  button?: CardButtonProps | null;
}

export interface MarketingProductsCardsProps {
  cards: ProductCardInterface[];
  onLinkClick?: (link: { text: string; href: string }) => void;
}

const Card = styled.div<{ shadowActive?: boolean }>`
  ${({ theme }) => css`
    border-radius: ${theme.radius};
    display: flex;
    flex-direction: column;
    background: ${theme.palette.background.surface};
  `}
`;

const CardHeader = styled(Typography).attrs({ variant: 'h4', weight: 'medium', notch: false })`
  ${({ theme }) => css`
    background: ${theme.palette.focus.strong};
    padding: ${theme.spacing(4)};
    margin-bottom: ${theme.spacing(6)};
    color: ${theme.palette.background.surface};
  `}
`;

const CardTitle = styled(Typography).attrs({ variant: 'h4', weight: 'normal', notch: false })`
  ${({ theme }) => css`
    margin: 0 0 ${theme.spacing(6)};
    padding: 0 ${theme.spacing(4)};
  `}
`;

const Description = styled(RichTextWrapper)`
  margin-bottom: ${({ theme }) => theme.spacing(6)};
  padding: 0 ${({ theme }) => theme.spacing(4)};
`;

const ButtonLink = styled(ButtonLinkNext)`
  align-self: flex-start;
  margin: 0 ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

const StyledList = styled.ul`
  list-style-type: none;
  padding-left: ${({ theme }) => theme.spacing(4)};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

const StyledListItem = styled.li`
  ${({ theme }) => css`
    &::before {
      content: '';
      display: inline-block;
      min-width: ${theme.spacing(5)};
      min-height: ${theme.spacing(5)};
      margin-right: ${theme.spacing(2)};
      background-image: url('/icons/checkmark-blue.svg'); /* replace with your image path */
      background-repeat: no-repeat;
      background-size: contain;
      margin-bottom: -${theme.spacing(2)};
    }

    display: flex;
    padding: ${theme.spacing(2)} 0;
  `}
`;

export const MarketingProductsCardsComponent = ({ cards }: MarketingProductsCardsProps) => {
  return (
    <MarketingCardsGrid data-testid="marketing-product-cards" columns={cards.length}>
      {cards.map((card) => (
        <Card key={card.title}>
          <CardHeader>{card.subtitle}</CardHeader>

          <CardTitle component="h4">{card.title}</CardTitle>

          {card.button && (
            <ButtonLink href={card.button.href} key={card.button.href}>
              {card.button?.title}
            </ButtonLink>
          )}

          <StyledList>
            {card.bulletItems?.map((bulletItem) => (
              <StyledListItem key={bulletItem}>{bulletItem}</StyledListItem>
            ))}
          </StyledList>

          <Description>{card.description}</Description>
        </Card>
      ))}
    </MarketingCardsGrid>
  );
};

export const MarketingProductsCards = memo(MarketingProductsCardsComponent);
