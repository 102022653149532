import { memo } from 'react';
import styled, { css } from 'styled-components';

import type { TextSectionComponentFields } from '@seuk/cms-api-client';
import { assertNever } from '@seuk/util';

import { NextImage } from '../../atoms';

export interface MarketingBadgeProps {
  icon?: string;
  backgroundColor?: TextSectionComponentFields['iconBackgroundColor'];
  location: TextSectionComponentFields['iconLocation'];
}

const Badge = styled.div<Pick<MarketingBadgeProps, 'backgroundColor' | 'location'>>`
  width: 3.5rem;
  height: 3.5rem;
  padding: 1rem;
  background: ${({ theme, backgroundColor }) =>
    backgroundColor === 'Grey' ? theme.palette.background.base : theme.palette.accent.secondary.strong};
  position: absolute;
  top: calc(100% - 2.75rem);
  left: calc(100% - 6.5rem);
  z-index: 1;
  border-radius: 50%;
  color: ${({ theme, backgroundColor }) =>
    backgroundColor === 'Grey' ? theme.palette.accent.secondary.strong : theme.palette.background.surface};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 5px rgb(0 0 0 / 10%);
  svg {
    max-width: 80%;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 5.75rem;
    height: 5.75rem;
    padding: 1rem;
    ${({ location }) => {
      switch (location) {
        case 'Top-right':
          return css`
            left: calc(100% - 8.75rem);
            top: -3.875rem;
          `;
        case 'Left-top':
          return css`
            left: -3.875rem;
            top: 1rem;
          `;
        case 'Bottom-left':
          return css`
            left: 3.875rem;
            top: calc(100% - 3.875rem);
          `;
        case 'Bottom-right':
          return css`
            left: unset;
            right: 3.875rem;
            top: calc(100% - 3.875rem);
          `;
        default:
          return assertNever(location);
      }
    }}
  }
`;

export const MarketingBadge = memo((props: MarketingBadgeProps) => {
  return (
    <Badge location={props.location} backgroundColor={props.backgroundColor}>
      {props.icon && (
        <NextImage
          width="72"
          height="72"
          src={props.icon}
          alt="marketing-badge"
          unoptimized={true}
          aria-hidden={true}
        />
      )}
    </Badge>
  );
});

MarketingBadge.displayName = 'MarketingBadge';
