import { richTextToReactNode } from '@seuk/cms-api-client';
import type { CardInterface, StepDescription } from '@seuk/design-system/organisms';

import type { SerializedCardInterface, SerializedStepDescription } from './serializers';

export const deserializeStepDescription = ({ text, ...rest }: SerializedStepDescription): StepDescription => ({
  ...rest,
  text: text ? richTextToReactNode(text) : null,
});

export const deserializeCardInterface = ({ description, ...rest }: SerializedCardInterface): CardInterface => ({
  ...rest,
  description: description ? richTextToReactNode(description) : null,
});
